/* ---------------------------------------------
 common scripts
 --------------------------------------------- */
(function($) {
	"use strict"; // use strict to start
	/* ---------------------------------------------
	 st preloader init
	 --------------------------------------------- */
	$(window).load(function() {
		$('#st-preloader').fadeOut();
		$('.st-preloader-circle').delay(350).fadeOut('slow');

		if (jQuery('.layer_cookie').length == 1) {

			jQuery('.close_cookie').click(function() {
				jQuery.cookie("cookie_policy", "yes", {
					path: '/',
					expires: 3650
				});
				jQuery('.layer_cookie').hide();
				return false;
			});

			jQuery('.close_cookie_info').click(function() {
				jQuery('.layer_cookie').fadeOut(100);
				return false;
			});
			if (!jQuery.cookie("cookie_policy")) {
				jQuery.cookie("cookie_policy", "no", {
					path: '/',
					expires: 3650
				});
				jQuery('.layer_cookie').show();
			} else {
				if (jQuery.cookie("cookie_policy") == 'yes') {
					jQuery('.layer_cookie').hide();
				} else {
					jQuery('.layer_cookie').show();
				}
			}
		}

	});

	/* === Back To Top === */

	(function() {
		$(' a.back-to-top').click(function() {
			$('html, body').animate({
				scrollTop: 0
			}, 800);
			return false;
		});
	}());

	$('#instafeed').owlCarousel({
		items: 8,
		itemsDesktop: [1199, 3],
		itemsDesktopSmall: [979, 3],
		navigation: true,
		navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
		pagination: false
	});
	$('#pinterestfeed').owlCarousel({
		items: 8,
		itemsDesktop: [1199, 3],
		itemsDesktopSmall: [979, 3],
		navigation: true,
		navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
		pagination: false
	});
	$('.masonery_area').masonry()
})(jQuery);